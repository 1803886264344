import { createRouter, createWebHashHistory } from "@ionic/vue-router";

let routes = [
  {
    path: "",
    component: () => import("@/common/Router.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("@/common/Dashboard/Dashboard"),
  },
  {
    path: "/menuservicios",
    component: () => import("@/common/Dashboard/MenuServicios"),
  },
  {
    path: "/login",
    component: () => import("@/common/Login/Login"),
  },
  {
    path: "/login/:empresastr",
    component: () => import("@/common/Login/Login"),
    props: true,
  },
  {
    path: "/servicios/:empresastr",
    component: () => import("@/common/Login/LoginServicios"),
    props: true,
  },
  {
    path: "/facturacion",
    component: () => import("@/common/FacturacionHome"),
  },
  {
    path: "/facturacion/:empresastr",
    component: () => import("@/common/FacturacionHome"),
    props: true,
  },
  {
    path: "/e/:empresastr",
    component: () => import("@/common/Login/Login"),
    props: true,
  },
  {
    path: "/pdf/:url",
    component: () => import("@/common/Visualizadores/PdfIframe"),
    props: true,
  },
  {
    path: "/*",
    component: () => import("@/common/404"),
  },
];

// A importarlos manualmente, webas
import ConfigRouter from "@/common/Config";
import PosSimpleRoute from "@/apps/PosSimple";
import ERPRouter from "@/apps/ERP";
import AlmacenRouter from "@/apps/Almacen";
import TareasRouter from "@/apps/Tareas";
import TareasAvanzadasRouter from "@/apps/TareasAvanzadas";
import CMMSRouter from "@/apps/CMMS";
import CfdiPdfRouter from "@/apps/CfdiPdf";
import FilePokerRouter from "@/apps/FilePoker";
import PCAssetsRouter from "@/apps/PCAssets";
import TimingRouter from "@/apps/Timing";
import FormsRouter from "@/apps/Forms";
import HerstellerRouter from "@/apps/Hersteller";
import TemplateRouter from "@/apps/Template";
import RecepcionGastosRouter from "@/apps/RecepcionGastos";
import BlogRouter from "@/apps/Blog";
import DescargaCfdiRouter from "@/apps/DescargaCfdi";
import ImpuestosRouter from "@/apps/Impuestos";
import MallainsaRouter from "@/apps/Mallainsa";
import EncorpServiceRouter from "@/apps/ENCORPservice";
import CadenaCustodiaRouter from "@/apps/CadenaCustodia";
import RhRouter from "@/apps/RH";
import LocomotiveRouter from "@/apps/Locomotive";
routes = routes
  .concat(ConfigRouter.router)
  .concat(PosSimpleRoute.router)
  .concat(ERPRouter.router)
  .concat(MallainsaRouter.router)
  .concat(AlmacenRouter.router)
  .concat(TareasRouter.router)
  .concat(TareasAvanzadasRouter.router)
  .concat(CMMSRouter.router)
  .concat(CfdiPdfRouter.router)
  .concat(FilePokerRouter.router)
  .concat(PCAssetsRouter.router)
  .concat(TimingRouter.router)
  .concat(FormsRouter.router)
  .concat(HerstellerRouter.router)
  .concat(RecepcionGastosRouter.router)
  .concat(BlogRouter.router)
  .concat(TemplateRouter.router)
  .concat(DescargaCfdiRouter.router)
  .concat(EncorpServiceRouter.router)
  .concat(CadenaCustodiaRouter.router)
  .concat(ImpuestosRouter.router)
  .concat(RhRouter.router)
  .concat(LocomotiveRouter.router);
//*/
const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});
export default router;
